import React from "react";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FooterBottom from "./FooterBottom";
import Modal from 'react-bootstrap/Modal';
import ReactGA from 'react-ga4';
import { connect } from "react-redux";

import TermsAndConditions from '../Home/TermsAndConditions.js';
import FooterBg from "../Admin/Question/View/Background/FooterBg";

class Footer extends React.Component {

    state = { showTermsAndConditions: false };

    showTermsAndConditions = () => {
        this.setState({
            showTermsAndConditions: true
        });
    }
    handleClose = () => {
        this.setState({
            showTermsAndConditions: false
        });
    }

    handleSocialLinksClick = (platform) => {
        ReactGA.event({
            category: 'Social Links',
            action: 'Click',
            label: platform,
        });
    };

    render() {
        const { t, location } = this.props;

        return (
            <>
                {
                    this.state.showTermsAndConditions ?
                        <Modal
                            size="xl"
                            show={this.state.showTermsAndConditions}
                            onHide={this.handleClose}
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title> <i className="icon fal fa-route-interstate"></i> {t('Pages.Register.TermsAndConditions.modal')} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="widget">
                                    <div className="widget-body">
                                        <TermsAndConditions></TermsAndConditions>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="col-12">
                                    <button className="btn btn-danger" onClick={this.handleClose}>
                                        <i className="icon fas fa-times"></i>
                                        {t('Close')}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        : ""
                }
                <footer>
                    <div className="position-relative">
                        <FooterBg absolute={true} />
                        <div className="footer-top">
                            <div className="container">
                                <div className="row mt-50">
                                    <div className="col-lg-4 col-md-6 mb-3">
                                        <div className="footer-widget widget-about mb-0">
                                            <h5 className="title">{t('About')}</h5>
                                            <p className=" text-justify">{t('Pages.AboutUs.Short')}</p>
                                            <ul className="contact">
                                                {
                                                    location && location.location && location.location.country_code !== "il" ?
                                                        <li>
                                                            <i className="fas fa-phone-alt"></i>
                                                            <a href={`Tel:${t('HeaderTop.phone_gp')}`}>
                                                                <span dir="ltr">
                                                                    {t('HeaderTop.phone_gp')}
                                                                </span>
                                                            </a>
                                                        </li>
                                                        :
                                                        <>
                                                            <li>
                                                                <i className="fas fa-phone-alt"></i>
                                                                <a href={`Tel:${t('HeaderTop.phone')}`}>
                                                                    <span dir="ltr">
                                                                        {t('HeaderTop.phone')}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <i className="fas fa-phone-alt"></i>
                                                                <a href={`Tel:${t('HeaderTop.phone1')}`}>
                                                                    <span dir="ltr">
                                                                        {t('HeaderTop.phone1')}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        </>
                                                }

                                                <li>
                                                    <i className="fas fa-map-marker-alt"></i>
                                                    {t('HeaderTop.address')}
                                                </li>
                                                <li>
                                                    <i className="fas fa-envelope-open-text"></i>
                                                    <a href={`mailto:${t('HeaderTop.email')}`}>
                                                        {t('HeaderTop.email')}
                                                    </a>
                                                </li>
                                            </ul>
                                            <ul className="social-icons">
                                                <li>
                                                    <Link onClick={() => this.handleSocialLinksClick("facebook")} to={{ pathname: "https://www.facebook.com/alarabeyya" }} aria-label="facebook" rel="noreferrer" className="facebook" target="_blank">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                {

                                                    location && location.location && location.location.country_code !== "il" ?
                                                        <li>
                                                            <Link onClick={() => this.handleSocialLinksClick("whatsapp")} to={{ pathname: "https://wa.me/970569859556" }} aria-label="whatsapp" rel="noreferrer" className="whatsapp" target="_blank">
                                                                <i className="fab fa-whatsapp"></i>
                                                            </Link>
                                                        </li>
                                                        :
                                                        <li>
                                                            <Link onClick={() => this.handleSocialLinksClick("whatsapp")} to={{ pathname: "https://wa.me/972508680705" }} aria-label="whatsapp" rel="noreferrer" className="whatsapp" target="_blank">
                                                                <i className="fab fa-whatsapp"></i>
                                                            </Link>
                                                        </li>
                                                }

                                                <li>
                                                    <Link onClick={() => this.handleSocialLinksClick("instagram")} to={{ pathname: "https://www.instagram.com/alarabeyya/" }} aria-label="instagram" rel="noreferrer" className="instagram" target="_blank">
                                                        <i className="fa-brands fa-instagram"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link onClick={() => this.handleSocialLinksClick("youtube")} to={{ pathname: "https://www.youtube.com/@hayya.alarabeyya" }} aria-label="youtube" rel="noreferrer" className="youtube" target="_blank">
                                                        <i className="fa-brands fa-youtube"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="footer-widget widget-blog">
                                            <h5 className="title">{t('Footer.SiteMap')}</h5>
                                            <ul className="footer-blog">
                                                <li>
                                                    <Link to="/">
                                                        <i className="fas fa-home"></i>
                                                        {t('Home')}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/About">
                                                        <i className="fas fa-info-square"></i>
                                                        {t('About')}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/Register">
                                                        <i className="fas fa-registered"></i>
                                                        {t('Register')}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/Search">
                                                        <i className="fas fa-search"></i>
                                                        {t('Pages.Search.title')}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/Login">
                                                        <i className="fas fa-sign-in-alt"></i>
                                                        {t('Login')}
                                                    </Link>
                                                </li>
                                                <li className="d-none">
                                                    <Link to="/">
                                                        <i className="fas fa-comments"></i>
                                                        {t('Footer.Menu.Visitor')}
                                                    </Link>
                                                </li>
                                                <li className="d-none">
                                                    <Link to="/">
                                                        <i className="fas fa-link"></i>
                                                        {t('Footer.Menu.Links')}
                                                    </Link>
                                                </li>
                                                <li className="d-none">
                                                    <Link to="/">
                                                        <i className="fas fa-vote-yea"></i>
                                                        {t('Footer.Menu.Vote')}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#" onClick={() => this.showTermsAndConditions()}>
                                                        <i className="fas fa-route-interstate"></i>
                                                        {t('Pages.Register.TermsAndConditions.modal')}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/Contact"><i className="fas fa-map-marker-alt"></i>{t('Contact')}</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 d-flex text-center justify-content-center">
                                        <div className="d-flex align-items-center">
                                            <div >
                                                <p className="text-center text-justify title h4 mb-3 font-weight-bold">
                                                    أَنا الْبَحْرُ في أَحْشائِهِ الدُّرُّ كامِنٌ
                                                    <br />
                                                    <br />
                                                    فَهَلْ سَأَلوا الْغَوّاصَ عَنْ صَدَفاتي
                                                </p>
                                                <Link to="/" aria-label={`${t('alarabeyya')}`} >
                                                    <LazyLoadImage src="/assets/images/logo/big-logo_dark_blue.png" alt={`${t('alarabeyya')}`} />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterBottom />
                </footer>
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user,
        location: state.location
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(Footer));