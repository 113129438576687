import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import moment from "moment";

import Footer from "../../Footer/FooterBottom";
import configData from "../../Config/countries.json";
import history from '../../../history';
import { servicesInitial } from "../../actions/";
import { fetchRanks } from '../actions/';
import DashBoardBg from "../../Admin/Question/View/Background/DashBoard/DashBoardBg";
import api from "../../../api/api";

class Profile extends React.Component {

    state = { countries: [], showPointInfo: false, showActiveUser: false, hideActiveUser: false }

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        this.state.countries = configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <div><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</div>,
            };
        });

        if (this.props.user && this.props.user.user && this.props.user.user.UserType.Id !== 1) {
            this.props.fetchRanks();
        }
        history.push("/Profile");
    }

    componentDidMount() {
        window.scrollToPosition("main-dashboard-section");
    }

    showPointInfo = () => {
        this.setState({
            showPointInfo: !this.state.showPointInfo
        });
    }

    activeUser = () => {
        const { t, user } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/RenewPayProglobalPayment/`, {
            SubscriptionId: user.user.SubscriptionId
        }).then(response => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fal fa-shield-check"></i>
                        {t('Pages.myBills.success_toast.Renew.Title')}</div>
                    <div className="h6">{t('Pages.myBills.success_toast.Renew.Body')}</div>
                </div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                showActiveUser: !this.state.showActiveUser,
                hideActiveUser: true
            });
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                currentStatus: "Wrong"
            });
        });
    }

    showActiveUser = () => {
        this.setState({
            showActiveUser: !this.state.showActiveUser
        });
    }

    render() {
        const { t, user, ranks } = this.props;
        if (user && user.user) {
            let Country = "";
            if (user.user.Country) {
                Country = this.state.countries.filter(p => p.value === user.user.Country)[0];
            }
            const ListOfBackgroundColor = ["#fffee8", "#eaf2ff", "#fff5fd"];
            let rate = 100;
            if (user.user.UserType.Id === 3 || user.user.UserType.Id === 4) {
                rate = 1000;
            }
            const date = moment().add(1, 'M');
            const ActivateUntilDate = moment(user.user.ActivateUntilDate, "DD/MM/YYYY");

            return (
                <>
                    {
                        this.state.showPointInfo ?
                            <Modal
                                size="lg"
                                centered
                                show={this.state.showPointInfo}
                                onHide={this.showPointInfo}
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false} >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <i className="icon fal fa-info-circle"></i>
                                        {t('Pages.Profile.point')}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category ">
                                        <div className="widget-body">
                                            <p className="text-justify">
                                                {t('Pages.Profile.pointInfo')}
                                            </p>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    {
                        this.state.showActiveUser ?
                            <Modal
                                size="md"
                                centered
                                show={this.state.showActiveUser}
                                onHide={this.showActiveUser}
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false} >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <i className="icon fal fa-money-bill-alt"></i>
                                        {t('Pages.Profile.services.active')}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category ">
                                        <div className="widget-body">
                                            <div className="title h4 text-center mb-3">
                                                سيتم تفعيل الاشتراك وخصم الرسوم السنوية
                                            </div>
                                            <div className="d-flex justify-content-center banner-buttons">
                                                <button className="custom-button" onClick={() => this.activeUser()} >
                                                    <span>
                                                        <i className="icon fal fa-money-bill-alt"></i>
                                                        {t('Pages.Profile.services.active')}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    <section className="pos-rel padding-bottom padding-top pt-4" id="main-dashboard-section" style={{
                        minHeight: '100vh'
                    }}>
                        <DashBoardBg user={user} />
                        <Helmet>
                            <title>
                                {`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`} | {t('Pages.Profile.title')}
                            </title>
                        </Helmet>
                        <div className="container ">
                            <div className="teacher-wrapper bg-white pos-rel py-5 px-3 ">
                                <div className="position-absolute" style={{
                                    top: '20px',
                                    left: '20px'
                                }}>
                                    {
                                        user.user.UserType.Id === 1 || (user.user.UserType.Id !== 5 && user.user.UserType.Id !== 6) ?
                                            <Link to="/User/Control" className="btn btn-dark mr-1" title={t('Pages.UserControl.title')}>
                                                <i className="fad fa-game-board-alt"></i> <span className="d-none d-lg-inline-block"> {t('Pages.UserControl.title')} </span>
                                            </Link> : ""
                                    }
                                    <Link to="/Profile/Edit" className="btn btn-danger mr-1" title={t('edit')}>
                                        <i className="fal fa-edit"></i> <span className="d-none d-lg-inline-block"> {t('edit')} </span>
                                    </Link>
                                    <Link to="/Profile/ChangePassword" className="btn btn-info mr-1" title={t('ChangePassword')}>
                                        <i className="fal fa-key"></i> <span className="d-none d-lg-inline-block"> {t('ChangePassword')} </span>
                                    </Link>
                                    <Link to="/Profile/Picture" className="btn btn-success mr-1" title={t('ChangePicture')}>
                                        <i className="fal fa-image"></i> <span className="d-none d-lg-inline-block"> {t('ChangePicture')} </span>
                                    </Link>
                                </div>

                                <div className="teacher-single-top mt-5">
                                    <div className="teacher-single-item d-flex flex-wrap justify-content-evenly align-items-center mb-0">
                                        <div className="teacher-single-thumb d-flex align-items-center justify-content-center">
                                            <LazyLoadImage src={`${user.user.Photo ? user.user.Photo : "/assets/images/teacher/teacher-single.jpg"}`} alt={`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`} />
                                        </div>
                                        <div className="teacher-single-content">
                                            <h3 className="title pb-4">
                                                {`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`}
                                            </h3>
                                            <ul>
                                                <li className="d-flex flex-wrap justify-content-start">
                                                    <span className="list-name">{t('Pages.Register.Birthday.title')}</span>
                                                    <span className="list-attr"> {user.user.Birthday}</span>
                                                </li>
                                                {
                                                    user.user.Sex ?
                                                        <>
                                                            <li className="d-flex flex-wrap justify-content-start">
                                                                <span className="list-name">{t('Pages.Register.Sex.title')}</span>
                                                                <span className="list-attr">
                                                                    <i className={`icon text-info fad fa-${user.user.Sex}`}></i>
                                                                    {t('Pages.Register.Sex.' + user.user.Sex)}
                                                                </span>
                                                            </li>
                                                        </> : ""
                                                }

                                                <li className="d-flex flex-wrap justify-content-start">
                                                    <span className="list-name">{t('Pages.Register.Country.title')}</span>
                                                    <span className="list-attr"> {Country ? Country.label : ""} </span>
                                                </li>
                                                <li className="d-flex flex-wrap justify-content-start">
                                                    <span className="list-name">{t('Pages.Register.Address.title')}</span>
                                                    <span className="list-attr">  {user.user.Address}</span>
                                                </li>

                                                {
                                                    user.user.Email ?
                                                        <>
                                                            <li className="d-flex flex-wrap justify-content-start">
                                                                <span className="list-name">{t('Pages.Register.Email.title')}</span>
                                                                <span className="list-attr">
                                                                    <a href={`mailto:${user.user.Email}`}>
                                                                        {user.user.Email}
                                                                    </a>
                                                                </span>
                                                            </li>
                                                        </> : ""
                                                }
                                                <li className="d-flex flex-wrap justify-content-start">
                                                    <span className="list-name">{t('Pages.Register.Phone.title')}</span>
                                                    <span className="list-attr">
                                                        <a href={`Tel:${user.user.Phone}`}>
                                                            <span dir="ltr">
                                                                {user.user.Phone}
                                                            </span>
                                                        </a>
                                                    </span>
                                                </li>
                                                <li className="d-flex flex-wrap justify-content-start">
                                                    <span className="list-name">{t('Pages.Register.Username.title')}</span>
                                                    <span className="list-attr">  {user.user.Username} </span>
                                                </li>
                                                <li className="d-flex flex-wrap justify-content-start">
                                                    <span className="list-name">{t('Pages.Register.SchoolName.title')}</span>
                                                    <span className="list-attr">  {user.user.School.Name} </span>
                                                </li>
                                                <li className="d-flex flex-wrap justify-content-start">
                                                    <span className="list-name">{t('Pages.Profile.IsActive.title')}</span>
                                                    <span className="list-attr">
                                                        {
                                                            user.user.IsActive === true ?
                                                                <>
                                                                    <i className="text-success icon fad fa-circle"></i> {t('Pages.Profile.IsActive.Active')}
                                                                </> :
                                                                <>
                                                                    <i className="text-danger icon fad fa-circle"></i> {t('Pages.Profile.IsActive.NotActive')}
                                                                </>
                                                        }
                                                    </span>
                                                </li>
                                                <li className="d-flex flex-wrap justify-content-start">
                                                    <span className="list-name">{t('Pages.Profile.LastLogin.title')}</span>
                                                    <span className="list-attr">  {user.user.LastLogin}</span>
                                                </li>
                                                {
                                                    user.user.UserType.Id === 2 || user.user.UserType.Id === 3 ?
                                                        <>
                                                            <li className="d-flex flex-wrap justify-content-start">
                                                                <span className="list-name">{t('Pages.Profile.ActivateUntilDate.title')}</span>
                                                                <span className="list-attr">
                                                                    {
                                                                        user.user.ActivateUntilDate !== "0" ?
                                                                            <>
                                                                                <i className="text-success icon fad fa-circle"></i>
                                                                                {user.user.ActivateUntilDate}
                                                                            </> :
                                                                            <>
                                                                                <i className="text-danger icon fad fa-circle"></i>
                                                                                {t('Pages.Profile.ActivateUntilDate.NotActive')}
                                                                            </>
                                                                    }
                                                                </span>
                                                            </li>
                                                            {
                                                                user.user.UserType.Id === 3 && user.user.School.Id === 1 ? "" :
                                                                    ActivateUntilDate <= date && !this.state.hideActiveUser ? <>
                                                                        {
                                                                            user.user.SubscriptionId ?
                                                                                <>
                                                                                    <li>
                                                                                        <div className="d-flex justify-content-center mt-3 align-items-center  ">
                                                                                            <Link to="#" onClick={() => this.showActiveUser()} className="custom-button mt-1" >
                                                                                                <span>
                                                                                                    <i className="icon fal fa-money-bill-alt"></i>
                                                                                                    {t('Pages.Profile.services.active')}
                                                                                                </span>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </li>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <li>
                                                                                        <div className="d-flex justify-content-center mt-3 align-items-center  ">
                                                                                            <Link to="/User/Renwal" className="custom-button mt-1" >
                                                                                                <span>
                                                                                                    <i className="icon fal fa-money-bill-alt"></i>
                                                                                                    {user.user.ActivateUntilDate !== "0" ? t('Pages.Profile.services.renewal') : t('Pages.Profile.services.active')}
                                                                                                </span>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </li>
                                                                                </>
                                                                        }
                                                                    </> : ""
                                                            }
                                                        </>
                                                        : ""
                                                }
                                            </ul>
                                        </div>
                                        {
                                            user.user.UserType.Id === 3 && user.user.School.Id === 1 ?
                                                <div className={`contact-form p-3 w-100 mb-5 justify-content-center mt-3 inner-box p-3 pt-4  `} style={{
                                                    background: 'rgb(170 233 255)',
                                                    border: '1px solid rgb(0 191 255)',
                                                    borderRadius: '10px'
                                                }}  >
                                                    <div>
                                                        <div className="title h4 mb-0 d-flex justify-content-center align-items-center">
                                                            <i className="icon fal fa-bell-exclamation  display-4"></i>
                                                            {t('Pages.Profile.ActivateSchool.title')}
                                                        </div>
                                                        <p>
                                                            {t('Pages.Profile.ActivateSchool.body')}
                                                        </p>
                                                    </div>
                                                </div>
                                                : ""
                                        }
                                        {
                                            user.user.ActivateUntilDate !== "0" && (user.user.UserType.Id === 2 || user.user.UserType.Id === 6) ?
                                                <div className={`contact-form p-3 w-100 justify-content-center mt-3 inner-box p-3 pt-4  `} style={{
                                                    background: 'rgb(170 233 255)',
                                                    border: '1px solid rgb(0 191 255)',
                                                    borderRadius: '10px'
                                                }}  >
                                                    <div>
                                                        <p className="title h5 p-2 mb-0 d-flex justify-content-center align-items-center">
                                                            {t('Pages.SubUsers.addSubUser')} :
                                                            <Link to="/User/Control" className="title mr-1" title={t('Pages.UserControl.title')}>
                                                                <i className="fad fa-game-board-alt"></i>  {t('Pages.UserControl.title')}
                                                            </Link>
                                                            <i className="fad fa-arrow-left mx-3"></i>
                                                            <Link to="/User/SubUsers" className="title mr-1" title={t('Pages.UserControl.subUsers')}>
                                                                <i className="fad fa-users"></i>  {t('Pages.UserControl.subUsers')}
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                                : ""
                                        }
                                    </div>
                                </div>
                            </div>

                            {
                                user.user.UserType.Id !== 1 ?
                                    <div className="teacher-wrapper pos-rel pt-0 mt-3 call-overlay bg_img oh" style={{
                                        backgroundImage: 'url("./assets/images/bg/call-bg.jpg")',
                                        boxShadow: '0px 0px 10px 0px #000',
                                        zIndex: '2'
                                    }}>
                                        <div className="row " >
                                            <div className="col-12">
                                                <div className="row justify-content-center mb-3 ">
                                                    <div className="col-lg-8">
                                                        <div className="section-header rank-header mb-0 bg-white p-5 " style={{
                                                            borderBottomLeftRadius: '25px',
                                                            borderBottomRightRadius: '25px'
                                                        }} >
                                                            <h3 className="title">
                                                                {t('Pages.Home.PositionAndPoint')}
                                                                <span className="mx-2 h5">
                                                                    <i onClick={() => this.showPointInfo()} className="icon fad fa-info-circle cursor-pointer text-danger"></i>
                                                                </span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                user.user.Point && user.user.Point.length > 0 ?
                                                    user.user.Point.map(item => {
                                                        let index = -1;
                                                        let startIndex = 4;
                                                        if (ranks && ranks.ranks && ranks.ranks.Point) {
                                                            const currentRank = ranks.ranks.Point.filter(p => p.Month === item.Month && p.Year === item.Year)[0];
                                                            if (user.user.UserType.Id === 5 && currentRank && currentRank.MostStudentsHavePoints && currentRank.MostStudentsHavePoints.filter(p => p.UserId === user.user.Id)[0]) {
                                                                index = currentRank.MostStudentsHavePoints.findIndex(p => p.UserId === user.user.Id);
                                                            } else if ((user.user.UserType.Id === 3 || user.user.UserType.Id === 4) && currentRank && currentRank.MostSchoolsHavePoints && currentRank.MostSchoolsHavePoints.filter(p => p.SchoolId === user.user.School.Id)[0]) {
                                                                index = currentRank.MostSchoolsHavePoints.findIndex(p => p.SchoolId === user.user.School.Id);
                                                            } else if ((user.user.UserType.Id === 2 || user.user.UserType.Id === 6) && currentRank && currentRank.MostUsersHavePoints && currentRank.MostUsersHavePoints.filter(p => p.UserId === user.user.Id)[0]) {
                                                                index = currentRank.MostUsersHavePoints.findIndex(p => p.UserId === user.user.Id);
                                                            }
                                                        }
                                                        return <div className="col-sm-12 col-md-4 col-lg-3" key={`"PointMonthly${item.Year}-${item.Month}`}>
                                                            <div className="facility-item w-100" >
                                                                <div className="facility-inner" style={{
                                                                    backgroundColor: index > -1 ? ListOfBackgroundColor[index] : ""
                                                                }}>
                                                                    <div className="ribbon-corner ribbon-fold" data-position="right top" title={t('Pages.Home.PointMonthly')}>{`${item.Year}-${item.Month}`}</div>
                                                                    {
                                                                        index > -1 && index <= 5 ?
                                                                            <div className="position-absolute" style={{
                                                                                top: '-20px',
                                                                                left: '-20px',
                                                                                zIndex: 9
                                                                            }}>
                                                                                <LazyLoadImage src={`/assets/images/archive/sa${startIndex + index}.png`} alt={t("Pages.Home.Position")} />
                                                                            </div>
                                                                            : ""
                                                                    }
                                                                    <h3 className="title" title={t('Pages.Home.Point')}> {Math.round(item.Point * rate)} </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                    : ""
                                            }
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </section>
                    <div className="position-sticky">
                        <Footer />
                    </div>
                </>
            );
        }
        return "";
    }
}

const mapStateToProps = state => {
    return {
        ranks: state.ranks,
        user: state.user,
    };
};

export default connect(mapStateToProps, { servicesInitial, fetchRanks })(withTranslation('common')(Profile));