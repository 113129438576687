import React from "react";
import { withTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import api from '../../../api/api';
import ServiceForm from '../../Home/Forms/ServiceForm';
import { billsInitial } from "../../actions/";

class UserBill extends React.Component {

    state = {
        showAddNewBill: false,
        showBillView: false,
        showBillEdit: false,
        showBillExpand: false,
        currentBill: null,
        touchedActivateUntilDate: false,
        errorActivateUntilDate: '',
        ActivateUntilDate: ''
    };

    componentDidMount() {
        this.billsInitial();
    }

    billsInitial() {
        this.props.billsInitial(this.props.user.Id);
    }

    handleClose = () => {
        this.setState({
            showAddNewBill: false,
            showBillExpand: false,
            showBillView: false,
            showBillEdit: false
        });
    }

    showAddNewBill = () => {
        this.setState({
            showAddNewBill: true
        });
    }

    showBillExpand = bill => {
        this.setState({
            showBillExpand: true,
            currentBill: bill,
            ActivateUntilDate: bill.ActivateUntilDate
        });
    }

    showBillView = id => {
        const { bills } = this.props;
        if (bills && bills.bills) {
            this.setState({
                showBillView: true,
                currentBill: bills.bills.filter(p => p.Id === id)[0]
            });
        }
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.setState({
            touchedActivateUntilDate: false,
            errorActivateUntilDate: ''
        });
    }

    onBlurActivateUntilDate = e => {
        const value = e.target.value;
        this.validateActivateUntilDate(value);
    }

    validateActivateUntilDate = value => {
        const { t } = this.props;
        if (!value) {
            this.setState({
                touchedActivateUntilDate: true,
                errorActivateUntilDate: t('Pages.UsersControl.bills.ActivateUntilDate.error')
            });
        } else if (!/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(value)) {
            this.setState({
                touchedActivateUntilDate: true,
                errorActivateUntilDate: t('Pages.UsersControl.bills.ActivateUntilDate.error_notmatch')
            });
        }
    }

    onSubmitChangeActivateDate = () => {
        const { t, user } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.ActivateUntilDate && this.ActivateUntilDate.ref && this.ActivateUntilDate.ref.value) {
            let postData = {
                ActivateUntilDate: this.ActivateUntilDate && this.ActivateUntilDate.ref ? this.ActivateUntilDate.ref.value : "",
                UserId: user.Id
            };

            api.post(`/ChangeBillActivateDate/${this.state.currentBill.Id}`,
                postData
            ).then(response => {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-hourglass-clock"></i> {t('Pages.UsersControl.toast.ChangeActivateDate.Title')}</div> <div className="h6">{t('Pages.UsersControl.toast.ChangeActivateDate.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.handleClose();
                this.billsInitial();
            }).catch(error => {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        } else {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.UsersControl.toast.ChangeActivateDate.error')}</div> <div className="h6"></div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    onSubmitAddBill = (postData, _toastId) => {
        const { t } = this.props;

        api.post(`/Bills/${this.props.user.Id}`,
            postData
        ).then(response => {
            if (response.data.code === "success") {

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.UsersControl.bills.success_toast.Title')}</div> <div className="h6">{t('Pages.UsersControl.bills.success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleClose();
                this.billsInitial();
            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    onSubmitEditBill = (postData, _toastId) => {
        const { t } = this.props;

        api.put(`/Bills/${this.state.currentBill.Id}`,
            postData
        ).then(response => {
            if (response.data.code === "success") {

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.UsersControl.bills.success_edit_toast.Title')}</div> <div className="h6">{t('Pages.UsersControl.bills.success_edit_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleClose();
                this.billsInitial();

            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    showBillEdit = id => {
        const { bills } = this.props;
        if (bills && bills.bills) {
            this.setState({
                showBillEdit: true,
                currentBill: bills.bills.filter(p => p.Id === id)[0]
            });
        }
    }

    sendBillLink = id => {
        if (id) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('Pages.UsersControl.bills.send_link_by_email_toast.Title')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.sendLinkByEmail(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-paper-plane"></i> {t('Pages.UsersControl.bills.send_link_by_email_toast.confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    sendLinkByEmail = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/SendBillByEmail/${id}`).then(response => {

            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fas fa-thumbs-up"></i>
                        {t('Pages.UsersControl.bills.send_link_by_email_toast_success.Title')}
                    </div>
                    <div className="h6">
                        {t('Pages.UsersControl.bills.send_link_by_email_toast_success.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
            navigator.clipboard.writeText(response.data.data.link);

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    renderTableBody() {
        const { t, bills } = this.props;
        if (bills.bills) {
            return bills.bills.map(bill => {
                return <tr key={`billItem-${bill.Id}`}>
                    <td className="text-truncate align-middle">
                        {bill.ActivateUntilDate}
                    </td>
                    <td className="text-center  align-middle">
                        <span className="h5">{bill.Price - bill.Discount}</span>
                    </td>
                    <td className="align-middle">
                        {bill.Note}
                    </td>
                    <td className="text-center align-middle">
                        {
                            bill.IsActive ?
                                <>
                                    <i className="text-success icon fas fa-circle" title={t('Pages.UsersControl.Active')}></i>
                                </>
                                :
                                <>
                                    <i className="text-danger icon fas fa-circle" title={t('Pages.UsersControl.NotActive')}></i>
                                </>
                        }
                    </td>
                    <td className="text-center align-middle">
                        {
                            bill.IsVisa ?
                                <>
                                    <i title={t('Pages.UsersControl.bills.IsVisa')} className="icon text-primary fab fa-cc-visa"></i>
                                </>
                                :
                                <>
                                    <i className="icon text-danger fad fa-user" title={bill.User.Name}></i>
                                </>
                        }
                    </td>
                    <td className="text-truncate align-middle">
                        <div className="text-truncate align-middle">
                            {bill.UpdateDate}
                        </div>
                    </td>
                    <td>

                        <span className="btn btn-sm btn-info m-1" onClick={() => this.showBillEdit(bill.Id)} title={t('Pages.UsersControl.bills.table.action.edit')}>
                            <i className="icon fas fa-edit"></i>
                            <span className="d-none d-lg-inline-block"> {t('Pages.UsersControl.bills.table.action.edit')} </span>
                        </span>

                        {
                            bill.Status === 0 && !bill.IsActive ?
                                <span className="btn btn-sm btn-primary m-1" onClick={() => this.sendBillLink(bill.Id)} title={t('Pages.UsersControl.bills.table.action.send')}>
                                    <i className="icon fas fa-paper-plane"></i>
                                    <span className="d-none d-lg-inline-block"> {t('Pages.UsersControl.bills.table.action.send')} </span>
                                </span>
                                : ""
                        }
                        {
                            bill.IsActive ?
                                <span className="btn btn-sm btn-warning m-1" onClick={() => this.showBillExpand(bill)} title={t('Pages.UsersControl.bills.table.action.expand')}>
                                    <i className="icon fas fa-hourglass-clock"></i>
                                    <span className="d-none d-lg-inline-block"> {t('Pages.UsersControl.bills.table.action.expand')} </span>
                                </span>
                                : ""
                        }

                        <span className="btn btn-sm btn-dark m-1" onClick={() => this.showBillView(bill.Id)} title={t('Pages.UsersControl.bills.table.action.view')}>
                            <i className="icon fas fa-eye"></i>
                            <span className="d-none d-lg-inline-block"> {t('Pages.UsersControl.bills.table.action.view')} </span>
                        </span>
                    </td>
                </tr>
            });
        }
    }

    renderTableBodyBillView() {
        if (this.state.showBillView) {
            if (this.state.currentBill) {
                return this.state.currentBill.Items.map(service => {
                    return <tr key={`billView-${service.Service.Id}`}>
                        <td>
                            {service.Service.Name}
                        </td>
                        <td>
                            {service.Service.Price}
                        </td>
                    </tr>
                });
            }
        }
        return "";
    }

    showAddNewSubscriptionBill = () => {
        const { t, user } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let link = `/PayProglobalPayment/?Email=${user.Email}`;
        if (user.UserType?.Id === 7) {
            link = `/PayProglobalPaymentOldSchool/${user.School.Id}`;
        }

        api.get(link,
        ).then(response => {
            toast.update(_toastId, {
                render: () => <div>
                    <div className="h5">
                        <i className="icon fas fa-thumbs-up"></i>
                        {t('Pages.UsersControl.bills.send_link_by_email_toast_success.Title')}
                    </div>
                    <div className="h6">
                        {t('Pages.UsersControl.bills.send_link_by_email_toast_success.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
            navigator.clipboard.writeText(response.data);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, user } = this.props;
        return <>

            {
                this.state.showBillEdit ?
                    <Modal
                        size="xl"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={this.state.showBillEdit}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div className="h5 mb-0">
                                    <i className="fad fa-edit text-primary icon"></i> {t("Pages.UsersControl.bills.edit")}
                                    :
                                    <span className="text-primary mx-1">
                                        {user.Name}
                                    </span>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ServiceForm user={user} currentBill={this.state.currentBill} onSubmit={this.onSubmitEditBill}></ServiceForm>
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            {
                this.state.showAddNewBill ?
                    <Modal
                        size="xl"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={this.state.showAddNewBill}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div className="h5 mb-0">
                                    <i className="fad fa-plus text-primary icon"></i> {t("Pages.UsersControl.bills.add")}
                                    :
                                    <span className="text-primary mx-1">
                                        {user.Name}
                                    </span>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ServiceForm isAdmin={true} user={user} onSubmit={this.onSubmitAddBill}></ServiceForm>
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            {
                this.state.showBillExpand ?
                    <Modal
                        size="md"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={this.state.showBillExpand}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div className="h5 mb-0">
                                    <i className="fad fa-hourglass-clock text-primary icon"></i> {t("Pages.UsersControl.bills.table.action.expand")}
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="widget widget-category mb-2">
                                <div className="widget-body">
                                    <div className="contact-form">

                                        <div className="form-group w-100">
                                            <div>
                                                <DateRangePicker
                                                    ref={ActivateUntilDate => this.ActivateUntilDate = ActivateUntilDate}
                                                    initialSettings={{
                                                        drops: 'up',
                                                        opens: 'center',
                                                        autoUpdateInput: false,
                                                        cancelClass: "btn btn-sm btn-danger",
                                                        singleDatePicker: true,
                                                        showDropdowns: true,
                                                        minYear: 2023,
                                                        defaultValue: this.state.ActivateUntilDate,
                                                        locale: {
                                                            format: 'DD/MM/YYYY',
                                                            cancelLabel: t('Close'),
                                                            firstDay: 6,
                                                            applyLabel: t('Apply'),
                                                            monthNames: [
                                                                `${t("months.January")}`,
                                                                `${t("months.February")}`,
                                                                `${t("months.March")}`,
                                                                `${t("months.April")}`,
                                                                `${t("months.May")}`,
                                                                `${t("months.June")}`,
                                                                `${t("months.July")}`,
                                                                `${t("months.August")}`,
                                                                `${t("months.September")}`,
                                                                `${t("months.October")}`,
                                                                `${t("months.November")}`,
                                                                `${t("months.December")}`
                                                            ],
                                                            daysOfWeek: [
                                                                t("daysOfWeek.Sunday"),
                                                                t("daysOfWeek.Monday"),
                                                                t("daysOfWeek.Tuesday"),
                                                                t("daysOfWeek.Wednesday"),
                                                                t("daysOfWeek.Thursday"),
                                                                t("daysOfWeek.Friday"),
                                                                t("daysOfWeek.Saturday")
                                                            ],
                                                        },
                                                    }}
                                                    onApply={this.handleApplyDate} >
                                                    <input
                                                        defaultValue={this.state.ActivateUntilDate}
                                                        name="ActivateUntilDate"
                                                        autoComplete="off"
                                                        required
                                                        type="text"
                                                        onBlur={e => this.onBlurActivateUntilDate(e)}
                                                        className={`form-control ${(this.state.touchedActivateUntilDate && this.state.errorActivateUntilDate) ? 'is-invalid' : ''}`}
                                                        placeholder={t('Pages.UsersControl.bills.ActivateUntilDate.Placeholder')}
                                                        title={t('Pages.UsersControl.bills.ActivateUntilDate.title')}
                                                    />
                                                </DateRangePicker>
                                                {this.state.touchedActivateUntilDate && this.state.errorActivateUntilDate && <div className="invalid-feedback">{this.state.errorActivateUntilDate}</div>}
                                            </div>
                                        </div>
                                        <div className="form-group w-100 text-center">
                                            <button className="custom-button" onClick={() => this.onSubmitChangeActivateDate()}>
                                                <span>
                                                    <i className="icon fal fa-save"></i> {t('Pages.UsersControl.notes.Send')}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            {
                this.state.showBillView ?
                    <Modal
                        size="md"
                        centered
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false}
                        show={this.state.showBillView}
                        onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div className="h5 mb-0">
                                    <i className="fad fa-file-invoice-dollar text-primary icon"></i> {t("Pages.UsersControl.bills.table.action.view")}
                                    <i className="fad fa-hashtag text-danger mx-2"></i> {this.state.currentBill.Id}
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="widget widget-category">
                                <div className="widget-body">
                                    <div className='text-dark h3 mb-3'>
                                        {t('Pages.UsersControl.bills.ActivateUntilDate.title')} :
                                        <span className="text-danger">
                                            {this.state.currentBill.ActivateUntilDate}
                                        </span>
                                    </div>
                                    <div className="table-responsive-xl">
                                        <table className="table table-striped table-hover table-bordered">
                                            <thead>
                                                <tr className="text-center">
                                                    <th>
                                                        {t('Pages.UsersControl.bills.table.Service')}
                                                    </th>
                                                    <th>
                                                        {t('Pages.UsersControl.bills.table.Price')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderTableBodyBillView()}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td className="h5">
                                                        {t('Pages.UsersControl.bills.TotalPrice.title')}
                                                    </td>
                                                    <td className="h5">
                                                        <span className="text-success"> {this.state.currentBill.Price}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="h5">
                                                        {t('Pages.UsersControl.bills.Discount.title')}
                                                    </td>
                                                    <td className="h5">
                                                        <span className="text-danger"> {this.state.currentBill.Discount}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="h5">
                                                        {t('Pages.UsersControl.bills.NatPrice.title')}
                                                    </td>
                                                    <td className="h5">
                                                        {this.state.currentBill.Price - this.state.currentBill.Discount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="h5">
                                                        {t('Pages.UsersControl.bills.Payment.title')}
                                                    </td>
                                                    <td className="h5">
                                                        {this.state.currentBill.Payment}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="h5">
                                                        {t('Pages.UsersControl.bills.table.IsActive')}
                                                    </td>
                                                    <td className="h5">
                                                        {
                                                            this.state.currentBill.IsActive ?
                                                                <>
                                                                    <i className="text-success icon fas fa-circle" ></i>
                                                                    {t('Pages.UsersControl.Active')}
                                                                </>
                                                                :
                                                                <>
                                                                    <i className="text-danger icon fas fa-circle" ></i>
                                                                    {t('Pages.UsersControl.NotActive')}
                                                                </>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="h5">
                                                        {t('Pages.UsersControl.bills.table.CreationDate')}
                                                    </td>
                                                    <td className="h5">
                                                        {this.state.currentBill.CreationDate}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="h5">
                                                        {t('Pages.UsersControl.bills.table.UpdateDate')}
                                                    </td>
                                                    <td className="h5">
                                                        {this.state.currentBill.UpdateDate}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="h5">
                                                        {t('Pages.UsersControl.bills.table.User')}
                                                    </td>
                                                    <td className="h5">
                                                        {
                                                            this.state.currentBill.IsVisa ?
                                                                <>
                                                                    <i title={t('Pages.UsersControl.bills.IsVisa')} className="icon text-primary fab fa-cc-visa"></i>
                                                                    {t('Pages.UsersControl.bills.IsVisa')}
                                                                </>
                                                                :
                                                                <>
                                                                    <i className="icon text-danger fad fa-user" title={this.state.currentBill.User.Name}></i>
                                                                    {this.state.currentBill.User.Name}
                                                                </>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="h5">
                                                        <b>
                                                            {t('Pages.UsersControl.bills.Note.title')} :
                                                        </b>
                                                        <br />
                                                        {this.state.currentBill.Note}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            <Modal
                size="xl"
                centered
                backdrop="static"
                keyboard={false}
                enforceFocus={false}
                show={true}
                onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="h5 mb-0">
                            <i className="fad fa-file-invoice-dollar text-primary icon"></i> {t("Pages.UsersControl.action.bills")}
                            :
                            <span className="text-primary mx-1">
                                {user.Name}
                            </span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <button className="btn mb-2 mr-2 w-auto btn-dark" onClick={() => this.showAddNewBill()} title={t('Pages.UsersControl.bills.add')}>
                        <i className="icon fad fa-plus"></i>
                        <span className="d-none d-lg-inline-block"> {t('Pages.UsersControl.bills.add')} </span>
                    </button>
                    {
                        user.UserType?.Id === 2 || user.UserType?.Id === 7 ? <>
                            <button className="btn mb-2 mr-2 w-auto btn-info" onClick={() => this.showAddNewSubscriptionBill()} title={t('Pages.UsersControl.bills.add_Subscription')}>
                                <i className="icon fad fa-plus"></i>
                                <span className="d-none d-lg-inline-block"> {t('Pages.UsersControl.bills.add_Subscription')} </span>
                            </button>
                        </> : ""
                    }
                    <div className="widget widget-category">
                        <div className="widget-body">
                            <div className="table-responsive-xl">
                                <table className="table table-striped table-hover table-bordered">
                                    <thead>
                                        <tr className="text-center">
                                            <th>
                                                {t('Pages.UsersControl.bills.table.ActivateUntilDate')}
                                            </th>
                                            <th>
                                                {t('Pages.UsersControl.bills.table.Price')}
                                            </th>
                                            <th>
                                                {t('Pages.UsersControl.bills.table.Note')}
                                            </th>
                                            <th>
                                                {t('Pages.UsersControl.bills.table.IsActive')}
                                            </th>
                                            <th>
                                                {t('Pages.UsersControl.bills.table.User')}
                                            </th>
                                            <th>
                                                {t('Pages.UsersControl.bills.table.UpdateDate')}
                                            </th>
                                            <th>
                                                {t('Pages.UsersControl.bills.table.action.title')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderTableBody()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        bills: state.bills
    };
};

export default connect(mapStateToProps, { billsInitial })(withTranslation('common')(UserBill));
