import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';

import Footer from "../Footer/FooterBottom";
import ContactForm from "./Forms/ContactForm";
import GameObjects from "../Admin/Question/View/GameObjects";

class Contact extends React.Component {

    state = { success: false, currentStatus: "" };

    handleAfterSubmit = (value) => {
        this.setState({
            success: value,
            currentStatus: value === true ? "True" : "Wrong"
        });
    }

    renderForm() {
        const { t, user, location } = this.props;
        if (location.isLoaded) {
            return (
                <>
                    <h4 className="title">{t('Contact')}</h4>
                    <h5 className="title text-danger animate__animated animate__bounce">
                        {t('ContactUs.SubHed')}
                    </h5>
                    <ContactForm
                        user={user.user}
                        afterSubmit={this.handleAfterSubmit}
                        country_code={location.location.country_code}
                        Address={location.location.Address} />
                </>
            );
        } else {
            return <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-sync-alt fa-spin"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Loading")}
                    </h4>
                </div>
            </div>;
        }
    }

    renderBody() {
        if (!this.state.success) {
            return this.renderForm();
        } else {
            return this.renderSuccess();
        }
    }

    renderSuccess() {
        const { t } = this.props;
        return (
            <div className={`card card-custom card-stretch gutter-b  `}>
                <div className="card-body">
                    <div className="icon success animate">
                        <span className="line tip animateSuccessTip"></span>
                        <span className="line long animateSuccessLong"></span>
                        <div className="placeholder"></div>
                        <div className="fix"></div>
                    </div>
                    <div className="text-center">
                        <div className="text-center font-size-h1">
                            {t('ContactFormSendSuccess.Title')}
                        </div>
                        <p className="lead">{t('ContactFormSendSuccess.Body')}</p>
                        <div className="btn btn-success font-weight-bold  btn-lg btn-block" onClick={() => this.handleAfterSubmit(false)}>
                            <i className="icon fal fa-envelope"></i>
                            {t('ContactFormSendSuccess.Resend')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    FinishMove = () => {
        this.setState({
            currentStatus: ""
        });
    }

    handleSocialLinksClick = (platform) => {
        ReactGA.event({
            category: 'Social Links',
            action: 'Click',
            label: platform,
        });
    };

    render() {
        const { t, location } = this.props;

        return (
            <>
                <Helmet>
                    <title>{t("Contact")} | {t('alarabeyya')}</title>
                </Helmet>
                <section className="pos-rel padding-bottom padding-top oh" id="main-contact-section" style={{
                    backgroundColor: '#b8f0ff',
                    backgroundImage: 'url(/assets/images/contact/sea.png?v=2)',
                    backgroundPositionY: 'bottom',
                    backgroundPositionX: 'left',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    minHeight: '100vh'
                }}>
                    <div className="position-absolute user-select-none" style={{
                        right: '2%',
                        top: '10%',
                        animation: `dropObjectMovment linear forwards 5s 2s normal infinite`
                    }}><GameObjects objectCss="Cloud2" /></div>
                    <div className="position-absolute user-select-none" style={{
                        right: 'calc(50% - 169px)',
                        top: '0%',
                        animation: `dropObjectMovment linear forwards 4s normal infinite`
                    }}><GameObjects objectCss="Cloud1" /></div>
                    <div className="position-absolute user-select-none" style={{
                        left: '2%',
                        top: '10%',
                        animation: `dropObjectMovment linear forwards 5s normal infinite`
                    }}><GameObjects objectCss="Cloud3" /></div>

                    <div className="position-absolute MoveBoat" style={{
                        bottom: '60%',
                        right: '10%'
                    }}>
                        <div className="position-relative d-flex justify-content-center ">
                            <div className="position-absolute">
                                <GameObjects objectCss="WaterCircles" />
                            </div>
                            <div className="position-absolute" style={{
                                top: '-50px'
                            }}>
                                <GameObjects objectCss="BoysInBoat" status={this.state.currentStatus} FinishMove={this.FinishMove} />
                            </div>
                        </div>
                    </div>

                    <div className="position-absolute user-select-none w-100" style={{
                        backgroundImage: 'url(/assets/images/contact/footer.png?v=2)',
                        backgroundPositionY: 'bottom',
                        backgroundPositionX: 'left',
                        backgroundRepeat: 'no-repeat',
                        bottom: '0px',
                        height: '100%'
                    }}></div>
                    <div className="position-absolute user-select-none w-100" style={{
                        backgroundImage: 'url(/assets/images/contact/sub-footer.png?v=2)',
                        backgroundPositionY: 'bottom',
                        backgroundPositionX: 'left',
                        backgroundRepeat: 'no-repeat',
                        bottom: '0px',
                        height: '100%'
                    }}></div>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 order-lg-1 order-md-2 mb-3">
                                <div className="position-absolute user-select-none" style={{
                                    left: '-150px',
                                    bottom: '-50px'
                                }}><GameObjects objectCss="RedPhone" rotate={"5"} scale="0.8" transformOriginY="bottom" status={this.state.currentStatus} FinishMove={this.FinishMove} /></div>
                                <div className="contact-form-area bg-white mb-3">
                                    {this.renderBody()}
                                </div>
                            </div>
                            <div className="col-lg-4 order-lg-2 order-md-1 mb-3">
                                <div className="widget widget-category oh">
                                    <div className="widget-header">
                                        <h5 className="title font-weight-bold">{t("Contact")}</h5>
                                    </div>
                                    <div className="widget-body ">
                                        <ul>
                                            {
                                                location && location.location && location.location.country_code !== "il" ?
                                                    <li>
                                                        <a className="font-weight-bold" href={`Tel:${t('HeaderTop.phone_gp')}`}>
                                                            <span>
                                                                <i className="fal fa-phone-alt" style={{
                                                                    color: '#ff4da1'
                                                                }}></i>
                                                                <span dir="ltr">
                                                                    {t('HeaderTop.phone_gp')}
                                                                </span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    :
                                                    <>
                                                        <li>
                                                            <a className="font-weight-bold" href={`Tel:${t('HeaderTop.phone')}`}>
                                                                <span>
                                                                    <i className="fal fa-phone-alt" style={{
                                                                        color: '#ff4da1'
                                                                    }}></i>
                                                                    <span dir="ltr">
                                                                        {t('HeaderTop.phone')}
                                                                    </span>
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="font-weight-bold" href={`Tel:${t('HeaderTop.phone1')}`}>
                                                                <span>
                                                                    <i className="fal fa-phone-alt" style={{
                                                                        color: '#ff4da1'
                                                                    }}></i>
                                                                    <span dir="ltr">
                                                                        {t('HeaderTop.phone1')}
                                                                    </span>
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </>
                                            }
                                            <li>
                                                <a className="font-weight-bold" href={`mailto:${t('HeaderTop.email')}`}>
                                                    <span>
                                                        <i className="fal fa-envelope-open-text" style={{
                                                            color: '#ff4da1'
                                                        }}></i>
                                                        {t('HeaderTop.email')}
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <Link className="font-weight-bold" to="#">
                                                    <span>
                                                        <i className="fal fa-map-marker-alt" style={{
                                                            color: '#ff4da1'
                                                        }}></i>
                                                        {t('HeaderTop.address')}
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="font-weight-bold" to="#">
                                                    <span>
                                                        <i className="fal fa-mailbox" style={{
                                                            color: '#ff4da1'
                                                        }}></i>
                                                        {t('HeaderTop.mailbox')}
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="font-weight-bold" to="#">
                                                    <span>
                                                        <i className="fal fa-mail-bulk" style={{
                                                            color: '#ff4da1'
                                                        }}></i>
                                                        {t('HeaderTop.mail')}
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <ul className="social-icons d-flex m-0 flex-wrap align-items-center justify-content-center">
                                                    <li className="p-3">
                                                        <Link onClick={() => this.handleSocialLinksClick("facebook")} to={{ pathname: "https://www.facebook.com/alarabeyya" }} aria-label="facebook" rel="noreferrer" className="facebook align-items-center justify-content-center text-white" target="_blank">
                                                            <i className="fab fa-facebook-f"></i>
                                                        </Link>
                                                    </li>
                                                    {
                                                        location && location.location && location.location.country_code !== "il" ?
                                                            <li className="p-3">
                                                                <Link onClick={() => this.handleSocialLinksClick("whatsapp")} to={{ pathname: "https://wa.me/970569859556" }} aria-label="whatsapp" rel="noreferrer" className="whatsapp align-items-center justify-content-center text-white" target="_blank">
                                                                    <i className="fab fa-whatsapp"></i>
                                                                </Link>
                                                            </li>
                                                            :
                                                            <li className="p-3">
                                                                <Link onClick={() => this.handleSocialLinksClick("whatsapp")} to={{ pathname: "https://wa.me/972508680705" }} aria-label="whatsapp" rel="noreferrer" className="whatsapp align-items-center justify-content-center text-white" target="_blank">
                                                                    <i className="fab fa-whatsapp"></i>
                                                                </Link>
                                                            </li>
                                                    }

                                                    <li className="p-3">
                                                        <Link onClick={() => this.handleSocialLinksClick("instagram")} to={{ pathname: "https://www.instagram.com/alarabeyya/" }} aria-label="instagram" rel="noreferrer" className="instagram align-items-center justify-content-center text-white" target="_blank">
                                                            <i className="fa-brands fa-instagram"></i>
                                                        </Link>
                                                    </li>
                                                    <li className="p-3">
                                                        <Link onClick={() => this.handleSocialLinksClick("youtube")} to={{ pathname: "https://www.youtube.com/@hayya.alarabeyya" }} aria-label="youtube" rel="noreferrer" className="youtube align-items-center justify-content-center text-white" target="_blank">
                                                            <i className="fa-brands fa-youtube"></i>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        location: state.location
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(Contact));