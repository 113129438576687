import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class HeaderBottom extends React.Component {

    renderNotification = count => {
        if (count > 0) {
            if (count > 99) {
                return <span className="badge badge-danger mx-1 position-absolute" style={{
                    left: 0
                }}>+99</span>
            }
            else {
                return <span className="badge badge-danger mx-1 position-absolute" style={{
                    left: 0
                }}>{count}</span>
            }
        }
        return "";
    }

    render() {
        const { t, user, notifications, location } = this.props;

        let Books = 0;
        let Exams = 0;
        let Files = 0;
        let Lessons = 0;
        let Mission = 0;
        let Quizzes = 0;
        let Games = 0;
        if (notifications && notifications.Notification) {
            Books = notifications.Notification.Books;
            Exams = notifications.Notification.Exams;
            Files = notifications.Notification.Files;
            Lessons = notifications.Notification.Lessons;
            Mission = notifications.Notification.Mission;
            Quizzes = notifications.Notification.Quizzes;
            Games = notifications.Notification.Games;
        }
        return (
            <>
                <div className="header-bottom">
                    <div className="container">
                        <div className="header-wrapper justify-content-lg-cente">

                            <div className="logo py-2" data-aos="fade-left" data-aos-easing="linear" title={t('Home')}>
                                <Link to="/" title={t('Home')}>
                                    <LazyLoadImage src="/assets/images/logo/logo.png" alt={`${t('alarabeyya')}`} />
                                </Link>
                            </div>
                            <div className="menu-area">
                                <ul className="menu">

                                    {
                                        user && user.user && user.user.UserType && user.user.UserType.Id === 5 ?
                                            <>
                                                <li>
                                                    <Link to="/Student/DashBoard">
                                                        <i className="far fa-chalkboard-teacher"></i>  {t('PageHeader.StudyTrack')}
                                                        {this.renderNotification(Books + Lessons + Files + Exams + Quizzes)}
                                                    </Link>
                                                    <ul className="submenu">
                                                        <li>
                                                            <Link to="/Student/Books">
                                                                <i className="fas fa-book"></i> {t('PageHeader.Books')}
                                                                {this.renderNotification(Books)}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/Student/Lessons">
                                                                <i className="fas fa-layer-group"></i> {t('PageHeader.Lessons')}
                                                                {this.renderNotification(Lessons)}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/Student/Files">
                                                                <i className="fas fa-paperclip"></i> {t('PageHeader.Files')}
                                                                {this.renderNotification(Files)}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/Student/Exams">
                                                                <i className="fas fa-pencil-alt"></i> {t('PageHeader.Exams')}
                                                                {this.renderNotification(Exams)}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/Student/Quizzes">
                                                                <i className="fal fa-question"></i>  {t('PageHeader.Quizzes')}
                                                                {this.renderNotification(Quizzes)}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to="/Student/Missions">
                                                        <i className="fal fa-bullseye-arrow "></i> {t('PageHeader.MyMissions')}
                                                        {this.renderNotification(Mission)}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/Student/Games">
                                                        <i className="fal fa-gamepad-alt "></i> {t('PageHeader.MyGames')}
                                                        {this.renderNotification(Games)}
                                                    </Link>
                                                </li>
                                            </>
                                            :
                                            <>
                                                <li>
                                                    <Link to="/Tracks">
                                                        <i className="far fa-chalkboard-teacher"></i>  {t('PageHeader.StudyTrack')}
                                                    </Link>
                                                    <ul className="submenu">
                                                        <li>
                                                            <Link className="d-flex align-items-center" to="/Tracks/Files">
                                                                <i className="fas fa-paperclip"></i>   {t('PageHeader.Files')}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="d-flex align-items-center" to="/Tracks/Lessons">
                                                                <i className="fas fa-layer-group"></i>  {t('PageHeader.Lessons')}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="d-flex align-items-center" to="/Tracks/Exams">
                                                                <i className="fas fa-pencil-alt"></i>   {t('PageHeader.Exams')}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="d-flex align-items-center" to="/Tracks/Books">
                                                                <i className="fas fa-book"></i>   {t('PageHeader.Books')}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="d-flex align-items-center" to="/Tracks/Quizzes">
                                                                <i className="fal fa-question"></i>   {t('PageHeader.Quizzes')}
                                                            </Link>
                                                        </li>

                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to="/Games" className="d-flex align-items-center"> <i className="fal fa-gamepad-alt"></i> {t('PageHeader.ThirdTrack')}</Link>
                                                </li>
                                            </>
                                    }
                                    {
                                        user && user.user ? "" :
                                            <>
                                                {
                                                    location && location.location && location.location.country_code !== "il" ?
                                                        <li>
                                                            <Link to="/Prices"> <i className="fal fa-dollar-sign"></i> {t('PageHeader.Prices')}</Link>
                                                        </li>
                                                        :
                                                        <>
                                                            <li>
                                                                <Link to="/Register"> <i className="fal fa-registered"></i> {t('Register_Now')}</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/Login"> <i className="fal fa-sign-in-alt"></i> {t('Login')}</Link>
                                                            </li>
                                                        </>
                                                }
                                                <li className={`d-md-none d-sm-block  `} >
                                                    <Link to="/Registration"> <i className="fal fa-registered"></i> {t('PageHeader.TestFree')}</Link>
                                                </li>
                                            </>
                                    }
                                    {
                                        user && user.user && user.user.UserType && user.user.UserType.Id === 5 ? "" :
                                            <>
                                                <li title={t('Contact')}>
                                                    <Link to="/Contact"><i className="fas fa-map-marker-alt"></i> {t('Contact')}</Link>
                                                </li>
                                            </>
                                    }
                                </ul>
                            </div>
                            {
                                user && user.user ? <div></div> :
                                    location && location.location && location.location.country_code !== "il" ?
                                        <Link to="/Registration" title={t('PageHeader.TestFree')} className={`custom-button d-none d-md-block  `} >
                                            <span >
                                                {t('PageHeader.TestFree')}
                                            </span>
                                        </Link>
                                        : <div></div>

                            }
                            <div className="menu-area d-lg-none">
                                <div className="header-bar">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className="ellepsis-bar">
                                    <i className="fas fa-ellipsis-h"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        notifications: state.notifications,
        location: state.location
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(HeaderBottom));